<template>
    <div class="new-home">
        <div class="logo">
            <img src="@/assets/new_img/img02.jpg" alt="" srcset="">
        </div>
        <div class="txt">
            <img src="@/assets/new_img/img01.jpg" alt="" srcset="">
        </div>
        <div class="txt1">
            “陪伴你的上岸路”
        </div>
        <div class="txt2">
            加入交流群 
            <img src="@/assets/new_img/icon01.png" alt="" srcset="">
        </div>
        <div class="main">
            <div class="left" @click="goTo('/new_menu')">
                <img src="@/assets/new_img/img03.png" alt="" srcset="">
            </div>
            <div class="right">
                <div class="top" @click="goTo('/main')">
                    <img src="@/assets/new_img/img04.png" alt="" srcset="">
                </div>
                <div class="btm">
                    <img @click="goTo('/directory/realQuestion')" src="@/assets/new_img/img05.png" alt="" srcset="">
                    <img @click="goTo('/')" src="@/assets/new_img/img06.png" alt="" srcset="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: 'value'
            }
        },
        methods: {
            goTo(url) {
                this.$router.push(url)
            }
        },
    }
</script>

<style lang="less" scoped>
    .new-home{
        background: url('./../../assets/new_img/bg01.jpg') no-repeat;
        background-size: cover;
        width: 100vw;
        min-height: 100vh;
        .logo{
            display: flex;
            justify-content: center;
            padding-top: 80px;
            img{
                width: 58px;
                height: 45px;
            }
        }
        .txt{
            padding-top: 16px;
            display: flex;
            justify-content: center;
            padding-bottom: 66px;
            img{
                height: 30px;
            }
        }
        .txt1{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 22px;

            color: #666666;
            text-align: center;
            padding-bottom: 8px;
        }
        .txt2{
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 17px;
            /* identical to box height */
            color: #415FD5;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 6.54px;
                margin-left: 5px;
            }
        }
        .main{
            position: fixed;
            bottom: 40px;
            display: flex;
            padding-left: 20px;
            .left{
                padding-right: 12px;
                img{
                    width: 159px;
                    height: 166px;
                }
            }
            .right{
                .top{
                    img{
                        width: 164px;
                        height: 75px;
                    }
                }
                .btm{
                    padding-top: 12px;
                    display: flex;
                    justify-content: space-between;
                    img{
                        width: 76px;
                        height: 79px;
                    }
                }
            }
        }
    }
</style>